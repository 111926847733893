<template>
    <div v-loading="$wait.is('avatar.*')" class="relative employee-details-menu" element-loading-spinner="el-custom-spinner">
        <el-button
            v-if="avatarSrc"
            class="absolute top-0 right-0 w-8 h-8 p-0 rounded flex justify-center items-center"
            type="danger"
            size="mini"
            @click="handleDeleteAvatar"
        >
            <fa-icon :icon="['fas', 'trash']" />
        </el-button>
        <img v-if="avatarSrc" class="self-center rounded-lg" :src="avatarSrc" alt="">

        <el-upload
            v-else
            ref="upload"
            class="w-full"
            action="#"
            list-type="picture-card"
            :show-file-list="false"
            :on-change="handleAvatarChange"
            :multiple="false"
            accept=".jpg,.png,.jpeg"
            :limit="1"
            :auto-upload="false"
        >
            <i slot="default" class="el-icon-camera" />
        </el-upload>
    </div>
</template>
<script>
export default {
    props: {
        avatarSrc: {
            type:    String,
            default: '',
        },
    },

    data() {
        return {
            employeeUuid: this.$route.params.uuid,
            file:         null,
        };
    },

    methods: {
        async handleAvatarChange(file) {
            if (!file) return;
            try {
                this.$wait.start('avatar.*');
                const formData = new FormData();
                formData.append('file', file.raw);

                await axios.post(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/${this.employeeUuid}/upload_avatar`,
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });

                this.$notify.success({ title: 'Success' });
                this.$emit('change');
            } finally {
                this.$wait.end('avatar.*');
            }
        },

        async handleDeleteAvatar() {
            try {
                this.$wait.start('avatar.*');
                await axios.delete(`${process.env.VUE_APP_CORE_API_URL}/employees/employees/${this.employeeUuid}/delete_avatar`);
                this.$notify.success({ title: 'Success' });
                this.$emit('change');
                this.$wait.end('avatar.*');
            } finally {
                this.$wait.end('avatar.*');
            }
        },
    },
};
</script>
<style>

    .employee-details-menu .el-upload--picture-card {
        width: 210px !important;
        height: 210px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
